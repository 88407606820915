import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/auth";
const { auth, firestore, functions, storage } = firebase;
import store from "../store";

import errorTranslate from "@shared/utils/errorTranslate";
import UnauthenticatedError from "./error/UnauthenticatedError";
import InvalidArgumentError from "./error/InvalidArgumentError";
import { routerReplaceAfterLanguageChange } from "@web/utils/router";
import { DEFAULT_LOCALE, getRouteLocale } from "@web/constants/language";

const allUserRouteNames = ["profile", "profile-saved", "profile-added", "profile-cv", ""];

const FORM_STATES = {
    IDLE: "IDLE",
    PENDING: "PENDING",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR"
};

const initialState = {
    formState: {
        profile: FORM_STATES.IDLE,
        avatar: FORM_STATES.IDLE,
        email: FORM_STATES.IDLE,
        password: FORM_STATES.IDLE,
        deleteAccount: FORM_STATES.IDLE,
        submitPost: FORM_STATES.IDLE,
        userCv: FORM_STATES.IDLE,
        userCvUrl: FORM_STATES.IDLE,
        customCv: FORM_STATES.IDLE
    },
    formStateMessage: {
        profile: FORM_STATES.IDLE,
        avatar: FORM_STATES.IDLE,
        email: FORM_STATES.IDLE,
        password: FORM_STATES.IDLE,
        deleteAccount: FORM_STATES.IDLE,
        submitPost: FORM_STATES.IDLE,
        userCv: FORM_STATES.IDLE,
        userCvUrl: FORM_STATES.IDLE,
        customCv: FORM_STATES.IDLE
    },
    profile: null,
    profileUnsubscribe: null,
    userCv: null,
    hasUserCv: false
};

const userCvConverter = {
    toFirestore: data => {
        // Extract attributes from data that should not be stored in the document
        /* eslint-disable-next-line */
        const { id, ...rest } = data;
        return rest;
    },
    fromFirestore: (snapshot, options) => {
        const data = {
            ...snapshot.data(options),
            id: snapshot.id
        };
        return snapshot.exists ? data : null;
    }
};

const actions = {
    /**
     * Watch profile for changes for the currently authenticated user
     * and fetch the profile data as neaded. No need to call more
     * than once per session.
     *
     * @param {*} ctx Current store context.
     *
     * @throws {UnauthenticatedError} User must be authenticated.
     */
    WATCH_PROFILE({ state, commit, dispatch }) {
        const user = getUser();

        // Unsubscribe previous watcher
        if (state.profileUnsubscribe) {
            state.profileUnsubscribe();
        }

        const unsubscriber = firestore()
            .doc(`users/${user.uid}`)
            .onSnapshot(doc => {
                const data = doc.data();
                const previousLanguage = state.profile && state.profile.language ? state.profile.language : DEFAULT_LOCALE;
                const routeLocale = getRouteLocale();

                commit("SET_PROFILE", doc.exists ? { ...data, id: doc.id } : null);
                dispatch("LOAD_CV");

                if (previousLanguage !== data.language || routeLocale !== data.language) {
                    routerReplaceAfterLanguageChange(data.language);
                }
            });

        commit("SET_PROFILE_UNSUBSCRIBE", unsubscriber);
    },

    /**
     * Set profile image for the currently authenticated user.
     *
     * @param {*} ctx Current store context.
     * @param {File|Blob} avatar User's new avatar.
     *
     * @throws {UnauthenticatedError} User must be authenticated.
     */
    async UPLOAD_PROFILE_AVATAR({ commit, dispatch }, avatar) {
        const user = getUser();
        commit("SET_FORM_STATE", { name: "avatar", state: FORM_STATES.PENDING });

        try {
            const snapshot = await storage()
                .ref(`/avatar/${user.uid}`)
                .put(avatar);
            const url = await snapshot.ref.getDownloadURL();
            dispatch("UPDATE_PROFILE", { avatar: url });
            commit("SET_FORM_STATE", { name: "avatar", state: FORM_STATES.SUCCESS });
        } catch (error) {
            const { message } = errorTranslate(error);
            commit("SET_FORM_STATE", { name: "avatar", state: FORM_STATES.ERROR, message });
        }
    },

    /**
     * Set cv for the currently authenticated user.
     *
     * @param {*} ctx Current store context.
     * @param {File|Blob} cv User's new cv.
     *
     * @throws {UnauthenticatedError} User must be authenticated.
     */
    async UPLOAD_PROFILE_CUSTOM_CV({ commit, dispatch }, cv) {
        const user = getUser();
        commit("SET_FORM_STATE", { name: "customCv", state: FORM_STATES.PENDING });

        try {
            const snapshot = await storage()
                .ref(`/uploads/custom_cv_${user.uid}`)
                .put(cv);
            const url = await snapshot.ref.getDownloadURL();
            dispatch("UPDATE_PROFILE", { customCv: url });
            commit("SET_FORM_STATE", { name: "customCv", state: FORM_STATES.SUCCESS });
        } catch (error) {
            const { message } = errorTranslate(error);
            commit("SET_FORM_STATE", { name: "customCv", state: FORM_STATES.ERROR, message });
        }
    },

    /**
     * Update profile information for the currently authenticated user.
     *
     * @param {*} ctx Current store context.
     * @param {*} payload New profile data.
     *
     * @throws {UnauthenticatedError} User must be authenticated.
     */
    async UPDATE_PROFILE({ commit, dispatch, state }, payload) {
        const user = getUser();
        commit("SET_FORM_STATE", { name: "profile", state: FORM_STATES.PENDING });

        const profileRef = firestore()
            .collection("users")
            .doc(user.uid);

        const cvRef = firestore()
            .collection("users_cv")
            .withConverter(userCvConverter)
            .doc(user.uid);

        const isPhoneNumberChanged = payload.phoneNumber !== state.profile.phoneNumber;
        const isCv = (await cvRef.get()).exists;

        try {
            await profileRef.set(payload, { merge: true });
            commit("SET_FORM_STATE", { name: "profile", state: FORM_STATES.SUCCESS });
        } catch (error) {
            const { message } = errorTranslate(error);
            commit("SET_FORM_STATE", { name: "profile", state: FORM_STATES.ERROR, message });
        }

        if (isPhoneNumberChanged && isCv) {
            commit("SET_FORM_STATE", { name: "userCv", state: FORM_STATES.PENDING });

            try {
                await cvRef.set({ general: { phoneNumber: payload.phoneNumber } }, { merge: true });

                commit("SET_FORM_STATE", { name: "userCv", state: FORM_STATES.SUCCESS });
                dispatch("LOAD_CV");
            } catch (error) {
                const { message } = errorTranslate(error);
                commit("SET_FORM_STATE", { name: "userCv", state: FORM_STATES.ERROR, message });
            }
        }
    },

    /**
     * Set a new email address to the currently authenticated user.
     *
     * Set a user's email address is security-sensitive action
     * so the user must have signed in recently. Therefore,
     * the user may be prompted for re-authentication.
     *
     * @param {*} ctx Current store context.
     * @param {string} email New email address.
     *
     * @throws {UnauthenticatedError} User must be authenticated.
     */
    async UPDATE_EMAIL({ commit, dispatch }, email) {
        const user = getUser();
        commit("SET_FORM_STATE", { name: "email", state: FORM_STATES.PENDING });

        try {
            await user.updateEmail(email);

            dispatch("UPDATE_PROFILE", { email });
            commit("SET_FORM_STATE", { name: "email", state: FORM_STATES.SUCCESS });
        } catch ({ code, message }) {
            if (code !== "auth/requires-recent-login") {
                commit("SET_FORM_STATE", { name: "email", state: FORM_STATES.ERROR, message });
            } else {
                store
                    .dispatch("AUTH/REAUTHENTICATE")
                    .then(() => dispatch("UPDATE_EMAIL", email))
                    .catch(error => {
                        const { message } = errorTranslate(error);
                        commit("SET_FORM_STATE", { name: "email", state: FORM_STATES.ERROR, message });
                    });
            }
        }
    },

    /**
     * Set a new password to the currently authenticated user.
     *
     * Set a user's password is security-sensitive action so
     * the user must have signed in recently. Therefore,
     * the user may be prompted for re-authentication.
     *
     * @param {*} ctx Current store context.
     * @param {string} password New password.
     *
     * @throws {UnauthenticatedError} User must be authenticated.
     */
    async UPDATE_PASSWORD({ commit, dispatch }, password) {
        const user = getUser();
        commit("SET_FORM_STATE", { name: "password", state: FORM_STATES.PENDING });

        try {
            await user.updatePassword(password);

            commit("SET_FORM_STATE", { name: "password", state: FORM_STATES.SUCCESS });
        } catch (error) {
            const { message } = errorTranslate(error);
            if (error.code !== "auth/requires-recent-login") {
                commit("SET_FORM_STATE", { name: "password", state: FORM_STATES.ERROR, message });
            } else {
                store
                    .dispatch("AUTH/REAUTHENTICATE")
                    .then(() => dispatch("UPDATE_PASSWORD", password))
                    .catch(error => {
                        const { message } = errorTranslate(error);
                        commit("SET_FORM_STATE", { name: "password", state: FORM_STATES.ERROR, message });
                    });
            }
        }
    },

    /**
     * Fetch and store the user's curriculum vitae (CV) if exists.
     *
     * @throws {UnauthenticatedError} User must be authenticated.
     */
    async LOAD_CV({ commit }) {
        const user = getUser();
        try {
            const cvRef = firestore()
                .collection("users_cv")
                .doc(user.uid);
            const cvUrlRef = firestore()
                .collection("users_cv_url")
                .doc(user.uid);

            const userCv = await cvRef.withConverter(userCvConverter).get();
            const userCvUrl = await cvUrlRef.get();

            const data = {
                ...(userCv.data() || {}),
                url: (userCvUrl.data() || {}).url
            };

            commit("SET_USER_CV", data);
        } catch (error) {
            window.console.error(error);
        }
    },

    /**
     * Updates user's curriculum vitae (CV). The user's CV will
     * be created if isn't exists.
     *
     * @param {*} ctx Current store context.
     * @param {*} payload New CV data.
     *
     * @throws {UnauthenticatedError} User must be authenticated.
     */
    async UPDATE_CV({ commit, dispatch }, payload) {
        const user = getUser();
        commit("SET_FORM_STATE", { name: "userCv", state: FORM_STATES.PENDING });

        const profileRef = firestore()
            .collection("users")
            .doc(user.uid);
        const cvRef = firestore()
            .collection("users_cv")
            .withConverter(userCvConverter)
            .doc(user.uid);

        try {
            const cvData = { ...payload };
            if (!(await cvRef.get()).exists) {
                cvData["createdAt"] = firestore.FieldValue.serverTimestamp();
            }

            const { general } = cvData;

            let profileData = {};
            ["firstName", "lastName", "email", "phoneNumber", "profession", "bio"].forEach(key => {
                if (general && general[key]) {
                    profileData[key] = general[key];
                }
            });

            await firestore().runTransaction(async transaction => {
                transaction.set(profileRef, profileData, { merge: true });
                transaction.set(cvRef, cvData, { merge: true });
            });

            commit("SET_FORM_STATE", { name: "userCv", state: FORM_STATES.SUCCESS });
            dispatch("LOAD_CV");
        } catch (error) {
            const { message } = errorTranslate(error);
            commit("SET_FORM_STATE", { name: "userCv", state: FORM_STATES.ERROR, message });
        }
    },

    /**
     * Updates user's curriculum vitae (CV) url. The user's CV url will
     * be created if isn't exists.
     *
     * @param {*} ctx Current store context.
     * @param {*} payload New CV url.
     *
     * @throws {UnauthenticatedError} User must be authenticated.
     */
    async UPDATE_CV_URL({ commit, dispatch }, payload) {
        const user = getUser();
        commit("SET_FORM_STATE", { name: "userCvUrl", state: FORM_STATES.PENDING });

        const normalizedUrl = String(payload)
            .trim()
            .toLowerCase();
        const cvUrlRef = firestore()
            .collection("users_cv_url")
            .doc(user.uid);
        const existsCvUrlRef = firestore()
            .collection("users_cv_url")
            .where("url", "==", normalizedUrl);

        try {
            const existsCvUrl = await existsCvUrlRef.get();

            if (existsCvUrl.empty) {
                await cvUrlRef.set({ url: normalizedUrl }, { merge: true });
                dispatch("UPDATE_CV", { url: normalizedUrl });
                commit("SET_FORM_STATE", { name: "userCvUrl", state: FORM_STATES.SUCCESS });
            } else {
                const message = "Bohužiaľ, zvolený odkaz sa už používa.";
                commit("SET_FORM_STATE", { name: "userCvUrl", state: FORM_STATES.ERROR, message });
            }
        } catch (error) {
            if (error.code == "permission-denied") {
                const message = "Neplatný formát. Povolené sú len číslice, písmená, podčiarkovník (_) a spojovník (-).";
                commit("SET_FORM_STATE", { name: "userCvUrl", state: FORM_STATES.ERROR, message });
            } else {
                const { message } = errorTranslate(error);
                commit("SET_FORM_STATE", { name: "userCvUrl", state: FORM_STATES.ERROR, message });
            }
        }
    },

    /**
     * Delete the currently authenticated user.
     *
     * Delete user is security-sensitive action so the user
     * must have signed in recently. Therefore, the user
     * may be prompted for re-authentication.
     *
     * @param {*} ctx Current store context.
     *
     * @throws {UnauthenticatedError} User must be authenticated.
     */
    async DELETE_ACCOUNT({ commit, dispatch }) {
        const user = getUser();
        commit("SET_FORM_STATE", { name: "deleteAccount", state: FORM_STATES.PENDING });

        try {
            await user.delete();

            commit("SET_FORM_STATE", { name: "deleteAccount", state: FORM_STATES.SUCCESS });
            store.dispatch("AUTH/SIGN_OUT");
            window.location.reload(true);
        } catch (error) {
            const { message } = errorTranslate(error);
            if (error.code !== "auth/requires-recent-login") {
                commit("SET_FORM_STATE", { name: "deleteAccount", state: FORM_STATES.ERROR, message });
            } else {
                store
                    .dispatch("AUTH/REAUTHENTICATE")
                    .then(() => dispatch("DELETE_ACCOUNT"))
                    .catch(error => {
                        const { message } = errorTranslate(error);
                        commit("SET_FORM_STATE", { name: "deleteAccount", state: FORM_STATES.ERROR, message });
                    });
            }
        }
    },

    /**
     * Send new verification email to the currently authenticated user.
     *
     *  @param {*} ctx Current store context.
     *
     * @throws {UnauthenticatedError} User must be authenticated.
     */
    async VERIFICATION({ commit }) {
        const user = getUser();
        commit("SET_FORM_STATE", { name: "profile", state: FORM_STATES.PENDING });

        try {
            await functions().httpsCallable("sendNewVerificationEmail")(user.uid);
            commit("SET_FORM_STATE", { name: "profile", state: FORM_STATES.SUCCESS });
        } catch (error) {
            const { message } = errorTranslate(error);
            commit("SET_FORM_STATE", { name: "profile", state: FORM_STATES.ERROR, message });
        }
    },

    /**
     * Store/remove the given entity to/from the user's profile.
     *
     * @param {*} ctx Current store context.
     * @param {Object} payload
     * @param {string} [payload.type="save"] Type of the operation.
     *                                       One of "save" or "remove".
     * @param {string} payload.entityName Name of the entity. One of "savedPosts",
     *                                    "savedEvents" or "savedProjects"
     * @param {string} payload.entityId ID of the entity.
     *
     * @throws {UnauthenticatedError} User must be authenticated.
     * @throws {InvalidArgumentError}
     */
    UPDATE_ENTITY({ dispatch }, { type = "save", entityName, entityId }) {
        const availabelEntities = ["savedPosts", "savedEvents", "savedProjects"];

        if (!availabelEntities.includes(entityName)) {
            throw new InvalidArgumentError(`Invalid entity name "${entityName}"`);
        }

        if (!entityId) {
            throw new InvalidArgumentError(`Invalid entity ID "${entityId}"`);
        }

        if (type === "save") {
            dispatch("UPDATE_PROFILE", { [entityName]: firestore.FieldValue.arrayUnion(entityId) });
        } else if (type === "remove") {
            dispatch("UPDATE_PROFILE", { [entityName]: firestore.FieldValue.arrayRemove(entityId) });
        } else {
            throw new InvalidArgumentError(`Invalid operation type "${type}"`);
        }
    },

    /**
     * TODO
     *
     * @param {*} ctx Current store context.
     * @param {string} postUrl
     *
     * @throws {UnauthenticatedError} User must be authenticated.
     */
    async SUBMIT_POST({ commit }, postUrl) {
        getUser(); // Check if the user is authenticated
        commit("SET_FORM_STATE", { name: "submitPost", state: FORM_STATES.PENDING });

        try {
            await functions().httpsCallable("userSubmitPost")({ postUrl });
            commit("SET_FORM_STATE", { name: "submitPost", state: FORM_STATES.SUCCESS });
        } catch (error) {
            const { message } = errorTranslate(error);
            commit("SET_FORM_STATE", { name: "submitPost", state: FORM_STATES.ERROR, message });
        }
    }
};

const mutations = {
    SET_FORM_STATE(state, { name, state: newState, message = null }) {
        if (name === "*") {
            Object.keys(state.formState).forEach(key => {
                state.formState[key] = newState;
                state.formStateMessage[key] = message;
            });
        } else {
            state.formState[name] = newState;
            state.formStateMessage[name] = message;
        }
    },
    SET_PROFILE(state, payload) {
        state.profile = payload;
    },
    SET_PROFILE_UNSUBSCRIBE(state, payload) {
        state.profileUnsubscribe = payload;
    },
    SET_USER_CV(state, payload) {
        state.userCv = payload;
        state.hasUserCv = payload && payload.url;
    },
    RESET(state) {
        if (state.profileUnsubscribe) {
            state.profileUnsubscribe();
        }

        for (let prop in state) {
            state[prop] = initialState[prop];
        }
    }
};

store.registerModule("USER", {
    namespaced: true,
    state: Object.assign({}, initialState),
    actions: {
        ROUTE_CHANGE_START: {
            root: true,
            handler({ commit }, route) {
                if (allUserRouteNames.includes(route.name)) {
                    commit("SET_FORM_STATE", { name: "*", state: FORM_STATES.IDLE });
                }
            }
        },
        ...actions
    },
    mutations
});

/**
 * Return currently authenticated user.
 *
 * @throws {UnauthenticatedError} User must be authenticated.
 */
function getUser() {
    const user = auth().currentUser;
    if (!user) throw new UnauthenticatedError();
    else return user;
}
