/* eslint-disable */
import Vue from "vue";
import firebase from "firebase/app";
import "firebase/firestore";

import formatRate from "@web/utils/format-rate";
import config from "@shared/config";
import store from "../store";
import urlcat from "urlcat";
import { compareDates, formatDate } from "@web/utils/date";
import { allProjectsBySearchString } from "@web/utils/projects";

const projectsIdsVisitedPerSession = new Set();

const ALLOWED_ATTRIBUTES = [
    "id",
    "name",
    "description",
    "duration",
    "visits",
    "rate",
    "specialRate",
    "timestamp",
    "dateOfStart",
    "location",
    "positionType",
    "type",
    "client",
    "technologies",
    "positionRequirements",
    "labels",
    "benefits",
    "published"
];

function pick(obj, keys) {
    return keys.map(k => (k in obj && obj[k] !== undefined ? { [k]: obj[k] } : {})).reduce((res, o) => Object.assign(res, o), {});
}

const computeTimeDiff = t => {
    let now = new Date().getTime();
    let diff = ((now - t) / 60000).toFixed(0);
    if (diff < 2) return `pred 1 minútou`;
    if (diff < 60) return `pred ${diff} minútami`;
    if (diff < 120) return `pred 1 hodinou`;
    if (diff < 1440) return `pred ${(diff / 60).toFixed(0)} hodinami`;
    if (diff >= 1440 && diff < 2880) return `pred 1 dňom`;
    return `pred ${(diff / 1440).toFixed(0)} dňami`;
};

const createConverter = (allowedAttributes = []) => ({
    toFirestore: modelObject => {
        return pick(modelObject, allowedAttributes);
    },
    fromFirestore: (snapshot, options) => {
        if (snapshot.exists) {
            const data = snapshot.data(options) || {};
            return {
                ...data,
                id: snapshot.id,
                name: String(data.name || "").trim(),
                computedPositionType: Array.isArray(data.positionType) ? data.positionType.join(", ") : data.positionType || "",
                isNew: Date.now() - Number(data.createdAt ? data.createdAt.seconds * 1000 : data.timestamp) <= 7 * 864e5, // 7 days
                publishedAtRelative: computeTimeDiff(data.createdAt ? data.createdAt.seconds * 1000 : data.timestamp)
            };
        }

        return null;
    }
});

const createCollectionFetcher = (collectionName, dataConverter) => async () => {
    const collectionSnapshot = await firebase
        .firestore()
        .collection(collectionName)
        .withConverter(dataConverter)
        .get();

    return collectionSnapshot.docs.map(doc => doc.data());
};

const createDocumentFetcher = (collectionName, dataConverter) => async documentId => {
    const documentSnapshot = await firebase
        .firestore()
        .collection(collectionName)
        .doc(documentId)
        .withConverter(dataConverter)
        .get();

    return documentSnapshot.data();
};

const fetchActionCreator = (fetcher, callback = (ctx, data) => {}) => async (ctx, payload) => {
    try {
        const data = await fetcher(payload);
        await callback(ctx, data);
        return data;
    } catch (error) {
        console.error(error);
    }
};

const projectConverter = createConverter(ALLOWED_ATTRIBUTES);

/**
 * Returns a list of all projects.
 */
const fetchAllProjects = async () => {
    const collectionSnapshot = await firebase
        .firestore()
        .collection("projects")
        .withConverter(projectConverter)
        .where("status", "in", ["published", "hr"])
        .get();

    return collectionSnapshot.docs.map(doc => doc.data());
};

/**
 * Fetch projects by ids
 *
 * @param {String[]} ids IDs of projects to fetch.
 */
const fetchProjectsByIds = async (ids = []) => {
    const fetchItemsPromises = ids.map(async id => {
        const item = await firebase
            .firestore()
            .doc(`projects/${id}`)
            .withConverter(projectConverter)
            .get();
        return item.data();
    });

    const items = await Promise.all(fetchItemsPromises);

    // Filter falsy items
    return items.filter(Boolean);
};

/**
 * Returns the project based on the ID.
 *
 * @param {string} id - The project post id.
 */
const fetchProjetById = createDocumentFetcher("projects", projectConverter);

const initialState = {
    areFetched: false,
    projectsById: {}
};

const actions = {
    // FETCH COLLECTION
    fetchAllProjects: fetchActionCreator(fetchAllProjects, (ctx, data) => ctx.commit("SET_PROJECTS", data)),
    fetchAllProjectsByIds: fetchActionCreator(fetchProjectsByIds),

    // FETCH DOCUMENT
    fetchProjectById: fetchActionCreator(fetchProjetById, (ctx, item) => ctx.commit("ADD_PROJECT", item)),

    /**
     * Increment the number of visits of the project
     *
     * @param {*} ctx Current store context.
     * @param {Object} payload
     * @param {string} payload.id The project's ID.
     */
    async visit(ctx, { id }) {
        try {
            await fetch(urlcat(config.adminUrl, "api/external/projects/:id/visit", { id }), {
                method: "post"
            });
        } catch (e) {}
    }
};

const getters = {
    areProjectsFetched: state => state.areFetched,
    allProjects: state =>
        Object.values(state.projectsById || {})
            .filter(project => ["published", "hr"].includes(project.status))
            .sort((p1, p2) => compareDates(p2.publishedAt, p1.publishedAt)),
    allProjectsByType: (state, getters) => type => getters.allProjects.filter(project => project.type === type),
    allProjectsByLocation: (state, getters) => location => getters.allProjects.filter(project => project.location === location),
    getProjectById: state => id => state.projectsById[id],
    allTypes: (state, getters) => {
        const unique = new Set(getters.allProjects.map(project => project.type));
        return [...unique].sort((t1, t2) => t1.localeCompare(t2));
    },
    allTypesWithCount: (state, getters) => (searchString, remote) => {
        let projects = getters.allProjects;

        if (searchString) {
            projects = allProjectsBySearchString(projects, searchString);
        }

        if (remote) {
            projects = projects.filter(project => project.remote && project.remotePercent === "100");
        }

        if (!projects.length) {
            return [];
        }

        const unique = new Set(projects.map(project => project.type));
        const allTypes = [...unique].sort((t1, t2) => t1.localeCompare(t2));

        return allTypes.map(type => [type, projects.filter(project => project.type === type).length]).sort((p1, p2) => p2[1] - p1[1]);
    }
};

const mutations = {
    SET_PROJECTS(state, payload) {
        state.projectsById = {};
        (payload || []).forEach(item => {
            if (item && item.id) {
                Vue.set(state.projectsById, item.id, item);
            }
        });

        state.areFetched = true;
    },

    ADD_PROJECT(state, item) {
        if (item && item.id) {
            Vue.set(state.projectsById, item.id, item);
        }
    }
};

store.registerModule("NEW_PROJECTS", {
    namespaced: true,
    state: Object.assign({}, initialState),
    actions,
    getters,
    mutations
});
