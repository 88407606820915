import firebase from "firebase/app";
import "firebase/firestore";

import store from "../store";
import { DEFAULT_LOCALE } from "@web/constants/language";

/*eslint-disable no-console*/

const normalizeTechnologyName = name => {
    return name
        ? String(name)
              .toLowerCase()
              .replace(/\s/g, "")
        : "";
};

const state = {
    locations: [],
    technologies_all: [],
    seniority_levels: [],
    stacks: [],
    filter_enums: [],
    technology_types: [],

    accesibleEnums: ["filter_enums", "locations", "seniority_levels", "stacks", "technologies_all", "technology_types"]
};

const getters = {
    allStacksNames: (state, _, rootState) => {
        const mapFunc = ({ name, nameEn, ...rest }) => ({
            name: rootState.locale === DEFAULT_LOCALE ? name : nameEn,
            value: name,
            ...rest
        });
        const mainStacks = state.stacks
            .filter(({ name }) => name !== "Iné")
            .map(mapFunc)
            .sort((a, b) => {
                if (a.value < b.value) return -1;
                if (a.value > b.value) return 1;
                return 0;
            });
        const otherStack = state.stacks.filter(({ name }) => name === "Iné").map(mapFunc);

        return otherStack ? [...mainStacks, ...otherStack] : mainStacks;
    },
    allMobileStacksNames: (state, getters) => {
        const MOBILE_STACKS = ["Android Developer", "iOS Developer"];
        return getters.allStacksNames.filter(({ name }) => MOBILE_STACKS.indexOf(name) !== -1);
    },
    allTechnologiesNames: state => {
        return (state.technologies_all || []).map(({ name }) => name).sort();
    },
    allTechnologies: state => {
        return state.technologies_all || [];
    },
    allTechnologiesIndexByName: state => {
        return (state.technologies_all || []).reduce((accumulator, currentValue) => {
            accumulator[currentValue.name] = currentValue;
            return accumulator;
        }, {});
    },
    allTechnologiesIndexByNormalizedName: state => {
        return (state.technologies_all || []).reduce((accumulator, currentValue) => {
            accumulator[normalizeTechnologyName(currentValue.name)] = currentValue;
            return accumulator;
        }, {});
    },
    allTechnologyTypesSK: state => {
        return (state.technology_types || []).map(({ svk }) => svk).sort();
    },
    allTechnologyTypes: state => {
        return state.technology_types || [];
    }
};

const actions = {
    init(ctx) {
        ctx.commit("INIT_STATE");
    },
    loadEnums(ctx) {
        ctx.state.accesibleEnums.forEach(e => {
            ctx.dispatch("getEnums", e);
        });
    },
    getEnums(ctx, enumsName) {
        firebase
            .firestore()
            .collection("enums")
            .doc(enumsName)
            .get()
            .then(enumsSnapshot => {
                if (enumsName == "filter_enums") {
                    ctx.commit("SET_ENUMS", {
                        name: enumsName,
                        value: {
                            usedLocations: enumsSnapshot.data().usedLocations.sort(),
                            usedTechnologies: enumsSnapshot.data().usedTechnologies.sort(),
                            usedStackTypes: enumsSnapshot.data().usedStackTypes.sort()
                        }
                    });
                } else ctx.commit("SET_ENUMS", { name: enumsName, value: enumsSnapshot.data().content.sort() });
            });
    }
};

const mutations = {
    INIT_STATE(state) {
        state.accesibleEnums.forEach(e => (state[e] = []));
    },
    SET_ENUMS(state, enumsObject) {
        state[enumsObject.name] = enumsObject.value;
    }
};

store.registerModule("ENUMS", {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
});
