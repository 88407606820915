import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

import store from "../store";
import Cookies from "js-cookie";

const projectsIdsVisitedPerSession = new Set();

// Lib imports
const actions = {
    // loadFilterEnums(ctx){
    //     firebase.firestore().collection('enums').doc('filter_enums')
    //         .get()
    //         .then((filterSnapshot)=>{ ctx.commit('SET_FILTER_ENUMS', filterSnapshot.data())})

    // },
    initiateGetProjects(ctx) {
        ctx.commit("SET_LOADING_STATE", true);

        let initial = firebase
            .firestore()
            .collection("projects")
            .where("status", "==", "published")
            .orderBy("timestamp", "desc")
            .limit(ctx.state.pageLength);

        initial.get().then(dataSnapshot => {
            let projects = [];

            dataSnapshot.forEach(doc => {
                projects.push({
                    id: doc.id,
                    data: doc.data()
                });
            });

            ctx.commit("SET_PROJECTS", projects);
            ctx.commit("SET_LOADING_STATE", false);
        });
    },
    getMoreProjects(ctx) {
        let next = firebase
            .firestore()
            .collection("projects")
            .where("status", "==", "published")
            .orderBy("timestamp", "desc")
            .startAfter(ctx.state.startAfter) // last loaded project timestamp
            .limit(ctx.state.pageLength);

        ctx.commit("SET_LOADING_STATE", true);

        next.get().then(dataSnapshot => {
            let projects = [];
            dataSnapshot.forEach(doc => {
                projects.push({
                    id: doc.id,
                    data: doc.data()
                });
            });

            ctx.commit("APPEND_PROJECTS", projects);
            ctx.commit("SET_LOADING_STATE", false);

            if (dataSnapshot.size != ctx.state.pageLength) ctx.commit("SET_END");
        });
    },
    getProjectById(ctx, id) {
        ctx.commit("SET_CURRENT_PROJECT_LOADING_STATE", true);

        firebase
            .firestore()
            .collection("projects")
            .doc("" + id)
            .get()
            .then(project =>
                project.exists
                    ? ctx.commit("SET_CURRENT_PROJECT", Object.assign(project.data(), { projectId: id }))
                    : ctx.commit("SET_CURRENT_PROJECT_NOT_FOUND")
            )
            .catch(() => ctx.commit("SET_CURRENT_PROJECT_NOT_FOUND"));
    },
    // getTechnologies(ctx) {
    //     firebase.firestore().collection('enums').doc('technologies_all')
    //         .get()
    //         .then((technologiesSnapshot) => ctx.commit('SET_TECHNOLOGIES', technologiesSnapshot.data().content))
    // },
    // getLocations(ctx) {
    //     let locationsRef = firebase.firestore().collection('locations')
    //         .orderBy('name')

    //     locationsRef.get()
    //         .then((dataSnapshot) => {

    //             let locations = [];

    //             dataSnapshot.forEach((doc) => {
    //                 locations.push(doc.data())
    //             })

    //             ctx.commit('SET_LOCATIONS', locations)

    //         })
    // },
    getFilteredProjects(ctx, filter) {
        ctx.commit("SET_LOADING_STATE", true);
        ctx.commit("CLEAR_PROJECTS");

        let projectsQuery = firebase
            .firestore()
            .collection("projects")
            .where("status", "==", "published")
            .orderBy("timestamp", "desc");
        if (Array.isArray(filter.technologies) && filter.technologies.length)
            projectsQuery = projectsQuery.where("technologies", "array-contains-any", filter.technologies);

        if (filter.location) projectsQuery = projectsQuery.where("location", "==", filter.location);

        if (filter.type) projectsQuery = projectsQuery.where("type", "==", filter.type);

        projectsQuery.get().then(dataSnapshot => {
            let projects = [];
            if (dataSnapshot.empty) ctx.commit("SET_END");
            else {
                dataSnapshot.forEach(doc => {
                    projects.push({
                        id: doc.id,
                        data: doc.data()
                    });
                });
                ctx.commit("SET_PROJECTS", projects);
            }
            ctx.commit("SET_LOADING_STATE", false);
        });
    },
    processInterest(ctx, interestObject) {
        interestObject.cookies = Cookies.get("cookie_utms");
        ctx.commit("SET_PROJECT_INTEREST_FINISHED", true);
        let firebaseProcessInterest = firebase.functions().httpsCallable("processProjectInterest");

        /* eslint-disable no-console */

        if (interestObject.files) {
            let cvFile = interestObject.files[0];
            let uploadTask = firebase
                .storage()
                .ref("/CV/" + cvFile.name + " - " + makeid(10))
                .put(cvFile);
            return uploadTask.on(
                "state_changed",
                () => {}, // progress
                e => console.error(e), // on error

                // on complete
                function() {
                    uploadTask.snapshot.ref.getDownloadURL().then(function(url) {
                        interestObject.cvURL = url;
                        delete interestObject.files;
                        firebaseProcessInterest(interestObject)
                            .then(() => ctx.commit("SET_PROJECT_INTEREST_FINISHED", false))
                            .catch(e => console.error(e));
                    });
                }
            );
        } else {
            firebaseProcessInterest(interestObject)
                .then(() => {
                    ctx.commit("SET_PROJECT_INTEREST_FINISHED", false);
                })
                .catch(e => console.error(e));
        }
    },

    /**
     * Increment/decrement the number of visits of the
     * current project based on given amount.
     *
     * @param {*} ctx Current store context.
     * @param {Object} payload
     * @param {string} payload.id The project's ID.
     * @param {number} [payload.amount=1] Amount of vistits to increment/decrement.
     */
    async PROJECT_VISIT(ctx, { id, amount = 1 }) {
        const projectRef = firebase
            .firestore()
            .collection("projects")
            .doc(id);

        // Simple guard that protects updating project's visits multiple times
        // per session. This do not handles case, when user refresh the page.
        if (projectsIdsVisitedPerSession.has(id)) {
            return;
        }

        try {
            projectsIdsVisitedPerSession.add(id);
            return await projectRef.update({
                visits: firebase.firestore.FieldValue.increment(amount)
            });
        } catch (error) {
            // Do nothing
        }
    }
};
const state = {
    projects: [],
    pagesLoaded: 0,
    pageLength: 10,
    startAfter: null,
    atEnd: false,

    loading: false,

    currentProject: {},
    currentProjectLoading: false,
    currentProjectNotFound: Boolean,

    projectInterestLoading: false
};
const getters = {};
const mutations = {
    CLEAR_PROJECTS(state) {
        state.projects = [];
        state.pagesLoaded = 0;
        state.pageLength = 5;
        state.atEnd = false;
    },
    SET_FILTER_ENUMS(state, newFilterEnums) {
        let sortedEnums = {
            usedLocations: newFilterEnums.usedLocations.sort(),
            usedTechnologies: newFilterEnums.usedTechnologies.sort(),
            usedStackTypes: newFilterEnums.usedStackTypes.sort()
        };
        state.filterEnums = sortedEnums;
    },
    SET_PROJECT_INTEREST_FINISHED(state, newProjectInterestLoading) {
        state.projectInterestLoading = newProjectInterestLoading;
    },
    SET_LOCATIONS(state, newLocations) {
        state.locations = newLocations;
    },
    SET_TECHNOLOGIES(state, newTechnologies) {
        state.technologies = newTechnologies;
    },
    SET_LOADING_STATE(state, newLoadingState) {
        state.loading = newLoadingState;
    },
    SET_CURRENT_PROJECT_LOADING_STATE(state, newLoadingState) {
        state.currentProjectLoading = newLoadingState;
    },
    SET_CURRENT_PROJECT(state, project) {
        state.currentProject = project;
        state.currentProjectLoading = false;
        state.currentProjectNotFound = false;
    },
    SET_CURRENT_PROJECT_NOT_FOUND() {
        state.currentProjectLoading = false;
        state.currentProjectNotFound = true;
    },
    SET_END(state) {
        state.atEnd = true;
    },
    SET_PROJECTS(state, projects) {
        state.projects = projects;
        state.pagesLoaded = 1;
        state.startAfter = projects[projects.length - 1].data.timestamp;
    },
    APPEND_PROJECTS(state, newProjects) {
        let projects = state.projects;
        newProjects.forEach(e => {
            projects.push(e);
            state.startAfter = e.data.timestamp;
        });

        state.projects = projects;
        state.pagesLoaded = state.pagesLoaded + 1;
    }
};
store.registerModule("PROJECTS", {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
});

// helpers
function makeid(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}
