import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";

import { getCurrentUser } from "../firebase";
import store from "../store";
import routes from "./routes";
import i18n from "@web/assets/i18n";
import { DEFAULT_LOCALE } from "@web/constants/language";

const routerPushHandler = {
    apply: async (target, thisArg, argumentsList) => {
        try {
            return await target.apply(thisArg, argumentsList);
        } catch (error) {
            // Catch "Avoided redundant navigation" error and do nothing
            if (
                String(error.message)
                    .toLocaleLowerCase()
                    .indexOf("redundant navigation") !== -1
            ) {
                return;
            }

            throw error;
        }
    }
};
VueRouter.prototype.push = new Proxy(VueRouter.prototype.push, routerPushHandler);
VueRouter.prototype.replace = new Proxy(VueRouter.prototype.replace, routerPushHandler);
Vue.use(VueRouter);

const router = new VueRouter({
    routes,
    mode: "history"
});

/**
 * Code stolen from https://alligator.io/vuejs/vue-router-modify-head
 * If there's a bug, don't blame me :)
 */
const routerBeforeMeta = async to => {
    const locale = to.params.locale || "sk";
    const html = document.documentElement;

    html.setAttribute("lang", locale);
    i18n.locale = locale;

    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find(r => r.meta && r.meta.title);
    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find(r => r.meta && r.meta.metaTags);

    if (nearestWithTitle) document.title = nearestWithTitle.meta.title ? `${i18n.t(nearestWithTitle.meta.title)} – Koderia.sk` : "Koderia.sk";
    else document.title = "Koderia.sk";

    Array.from(document.querySelectorAll("[data-vue-index-controlled]")).map(el => el.parentNode.removeChild(el));
    if (!nearestWithMeta) return;
    nearestWithMeta.meta.metaTags
        .map(tagDef => {
            const tag = document.createElement("meta");
            Object.keys(tagDef).forEach(key => {
                tag.setAttribute(key, tagDef[key]);
            });
            tag.setAttribute("data-vue-index-controlled", "");
            return tag;
        })
        .forEach(tag => document.head.appendChild(tag));
};

const routerBeforeAppbar = async to => {
    const record =
        to.matched
            .slice()
            .reverse()
            .find(r => r.meta && r.meta.appBarStyle) || {};
    const { appBarStyle } = record.meta || {};

    store.dispatch("APPBAR_STYLE", appBarStyle);
};

router.beforeEach(async (to, from, next) => {
    const { name, path, params, meta, query, matched } = to;
    const { locale, ...restParams } = params;

    routerBeforeMeta(to);
    routerBeforeAppbar(to);

    const requiresAuth = matched.some(record => record.meta.requiresAuth);
    const requiresGuest = matched.some(record => record.meta.requiresGuest);

    const currentUser = await getCurrentUser();

    if (requiresAuth && !currentUser) {
        const in30Minutes = 1 / 48;
        const redirectTo = Cookies.get("redirect.after_login");

        if (!redirectTo) {
            Cookies.set("redirect.after_login", name, { expires: in30Minutes });
        }

        next({ path: `${locale ? `/${locale}` : ""}/${i18n.t("route_alias.auth_sign_in", locale || DEFAULT_LOCALE)}` });
    } else if (requiresGuest && currentUser) {
        next({ name: "Home", params });
    } else {
        if (meta.translateKey) {
            let shouldBeTranslatedTo = i18n.t(meta.translateKey, locale || DEFAULT_LOCALE);

            if (Object.keys(restParams).length) {
                Object.entries(restParams).forEach(([key, value]) => {
                    shouldBeTranslatedTo = shouldBeTranslatedTo.replace(`:${key}`, value);
                });
            }

            if (!path.includes(shouldBeTranslatedTo)) {
                const translatedPath = `/${locale ? `${locale}/` : ""}${shouldBeTranslatedTo}`;

                return next({ query, params, path: translatedPath });
            }
        }

        next();
    }
});

export default router;
