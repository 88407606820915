import Cookies from "js-cookie";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
const { auth, functions } = firebase;
import { getCurrentUser } from "../firebase";
import store from "../store";
import router from "../router";
import { getProvider, performReauthentication, performOAuthLoginWithProvider } from "./authentication";
import errorTranslate from "@shared/utils/errorTranslate";
import { getRouteLocale, SUPPORTED_LOCALES } from "@web/constants/language";
import { routerReplaceAfterLanguageChange } from "@web/utils/router";

const FORM_STATES = {
    IDLE: "IDLE",
    PENDING: "PENDING",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR"
};

const initialState = {
    formState: FORM_STATES.IDLE,
    formStateMessage: null,
    initialized: false,
    user: null
};

function redirectToOrigin() {
    router
        .push({
            name: "Home",
            params: { locale: getRouteLocale() }
        })
        .catch(() => {});
}

function redirectAfterLogin() {
    const redirectTo = Cookies.get("redirect.after_login");

    if (redirectTo) {
        setTimeout(() => {
            Cookies.remove("redirect.after_login");
        }, 250);

        try {
            const parsed = JSON.parse(redirectTo);
            router.push(parsed);
        } catch {
            // Try parse `redirect.after_login` cookie, if not an object then it should be a route name
            router.push({
                name: redirectTo,
                params: { locale: getRouteLocale() }
            });
        }
    } else {
        redirectToOrigin();
    }
}

const actions = {
    /**
     * @param {*} ctx Current store context.
     */
    async AUTH_STATE_CHANGED({ commit, state }) {
        const user = await getCurrentUser();

        if (!user) {
            store.commit("AUTH/RESET");
            store.commit("USER/RESET");

            if (!state.initialized) {
                let locale = SUPPORTED_LOCALES.find(item => navigator.language.includes(item));

                if (!locale) {
                    locale = SUPPORTED_LOCALES.find(item => navigator.languages.find(lang => lang.includes(item))) || "sk";
                }

                setTimeout(() => {
                    routerReplaceAfterLanguageChange(locale);
                }, 500);
            }
        } else {
            // Watch profile for changes.
            store.dispatch("USER/WATCH_PROFILE");

            commit("SET_USER", {
                uid: user.uid,
                email: user.email,
                emailVerified: user.emailVerified,
                displayName: user.displayName,
                photoURL: user.photoURL,
                token: user.token
            });
        }

        store.commit("AUTH/INITIALIZED", true);
    },

    /**
     * Perform the user sign in with the email and password.
     *
     * @param {*} ctx Current store context.
     * @param {Object} payload
     * @param {string} payload.email The user's email address.
     * @param {string} payload.password The user's password.
     * @param {boolean} payload.remember The remember me flag.
     */
    async SIGN_IN({ commit }, { email, password, remember }) {
        commit("SET_FORM_STATE", FORM_STATES.PENDING);
        const persistence = remember ? auth.Auth.Persistence.LOCAL : auth.Auth.Persistence.SESSION;

        try {
            await auth().setPersistence(persistence);
            await auth().signInWithEmailAndPassword(email, password);

            commit("SET_FORM_STATE", FORM_STATES.SUCCESS);
            redirectAfterLogin();
        } catch (error) {
            const { message } = errorTranslate(error);
            commit("SET_FORM_STATE", { formState: FORM_STATES.ERROR, formStateMessage: message });
        }
    },

    /**
     * Perform the user sign in with the desired provider.
     *
     * @param {*} ctx Current store context.
     * @param {string} providerId The desired provider.
     */
    async SIGN_IN_WITH_PROVIDER({ commit, dispatch }, providerId) {
        commit("SET_FORM_STATE", FORM_STATES.PENDING);

        try {
            await auth().setPersistence(auth.Auth.Persistence.LOCAL);
            const user = await performOAuthLoginWithProvider(getProvider(providerId));
            await dispatch("CREATE_USER_PROFILE", user);

            commit("SET_FORM_STATE", FORM_STATES.SUCCESS);
            redirectAfterLogin();
        } catch (error) {
            const { message } = errorTranslate(error);
            commit("SET_FORM_STATE", { formState: FORM_STATES.ERROR, formStateMessage: message });
        }
    },

    /**
     * Perform the user sign out.
     */
    async SIGN_OUT() {
        await auth().signOut();
        redirectToOrigin();
    },

    /**
     * Perform user re-authentication.
     */
    async REAUTHENTICATE() {
        try {
            await performReauthentication();
        } catch (error) {
            throw errorTranslate(error);
        }
    },

    /**
     * Perform the user sign up.
     *
     * @param {*} ctx Current store context.
     * @param {Object} payload
     * @param {string} payload.firstName The user's first name.
     * @param {string} payload.lastName The user's last name.
     * @param {string} payload.email The user's email address.
     * @param {string} payload.password The user's password.
     * @param {Object} payload.gdpr
     * @param {boolean} payload.gdpr.gdprValue The user's consent to terms and conditions.
     * @param {boolean} payload.gdpr.newsletterValue The user's consent to newsletter subscription.
     * @param {Object} payload.language
     */
    async SIGN_UP({ commit }, { firstName, lastName, email, password, gdpr, language }) {
        commit("SET_FORM_STATE", FORM_STATES.PENDING);

        const data = {
            firstName,
            lastName,
            email,
            password,
            gdpr: gdpr && gdpr.gdprValue === true,
            newsletter: gdpr && gdpr.newsletterValue === true,
            language,
        };

        try {
            await functions().httpsCallable("webAuthSignUp")(data);
            commit("SET_FORM_STATE", FORM_STATES.SUCCESS);
        } catch (error) {
            const { message } = errorTranslate(error);
            commit("SET_FORM_STATE", { formState: FORM_STATES.ERROR, formStateMessage: message });
        }
    },

    /**
     * Perform user passowrd reset.
     *
     * @param {*} ctx Current store context.
     * @param {*} payload
     * @param {string} payload.email The user's email address.
     */
    async RESET_PASSWORD({ commit }, { email }) {
        commit("SET_FORM_STATE", FORM_STATES.PENDING);

        try {
            await functions().httpsCallable("webAuthGeneratePasswordResetLink")(email);
            commit("SET_FORM_STATE", FORM_STATES.SUCCESS);
        } catch (error) {
            const { message } = errorTranslate(error);
            commit("SET_FORM_STATE", { formState: FORM_STATES.ERROR, formStateMessage: message });
        }
    },

    /**
     * Fill out user profile with additional info from OAuth providers if the user is new.
     *
     * @param {*} ctx Current store context.
     * @param {*} user The user instance.
     */
    CREATE_USER_PROFILE(ctx, user) {
        const isNewUser = user && user.additionalUserInfo && (user.additionalUserInfo.isNewUser || !user.user.metadata.lastSignInTime);

        if (!isNewUser) {
            return;
        }

        switch (user.additionalUserInfo.providerId) {
            case auth.GoogleAuthProvider.PROVIDER_ID:
                store.dispatch("USER/UPDATE_PROFILE", {
                    email: user.user.email,
                    firstName: user.additionalUserInfo.profile.given_name || null,
                    lastName: user.additionalUserInfo.profile.family_name || null,
                    avatar: user.user.photoURL || null
                });
                break;
            case auth.FacebookAuthProvider.PROVIDER_ID:
                store.dispatch("USER/UPDATE_PROFILE", {
                    email: user.user.email,
                    firstName: user.additionalUserInfo.profile.first_name || null,
                    lastName: user.additionalUserInfo.profile.last_name || null,
                    avatar: user.user.photoURL || null,
                    gender: user.additionalUserInfo.profile.gender || null
                });
                break;
            case auth.GithubAuthProvider.PROVIDER_ID:
                store.dispatch("USER/UPDATE_PROFILE", {
                    email: user.user.email,
                    bio: user.additionalUserInfo.profile.bio || null,
                    avatar: user.user.photoURL || null
                });
                break;
        }
    }
};
const getters = {
    isAdmin: state => {
        const { token } = state.user || {};
        const { claims } = token || {};
        const { admin } = claims || {};

        return admin === true;
    },

    isOwner: state => id => {
        const { uid } = state.user || {};
        return id && uid === id;
    }
};

const mutations = {
    SET_FORM_STATE(state, payload) {
        if (typeof payload === "string") {
            state.formState = payload;
            state.formStateMessage = null;
        } else {
            state.formState = payload.formState;
            state.formStateMessage = payload.formStateMessage || null;
        }
    },
    INITIALIZED(state, value) {
        state.initialized = !!value;
    },
    SET_USER(state, user) {
        state.user = user;
        state.userResolved = !!user;
    },
    RESET(state) {
        for (let prop in state) {
            state[prop] = initialState[prop];
        }
    }
};

store.registerModule("AUTH", {
    namespaced: true,
    state: Object.assign({}, initialState),
    actions: {
        ROUTE_CHANGE_START: {
            root: true,
            handler(ctx, route) {
                if (["auth-signin", "auth-signup", "auth-password"].includes(route.name) && route.name !== route.from.name) {
                    ctx.commit("SET_FORM_STATE", FORM_STATES.IDLE);
                }
            }
        },
        ...actions
    },
    mutations,
    getters
});
